
import { Routes, Route } from 'react-router-dom';
import './App.css';

import Home from 'Views/Home';

const App = () => {

    return (
        <div className={`App`}>
            <Routes>
                <Route path="/*" element={<Home />} />
            </Routes>
        </div>
    )
}

export default App;