import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { addDocs, getDocs } from "State/docs";
import { Between, Btn, Icon, Line, Rows, Header } from "UIKit";
import Settings from "Settings";

const Sidebar = ({ onSetIsOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [docs] = useSelector(state => [state.docs]);
    const params = useParams();
    const branch = params['*'].split('/')[0];
    const id = params['*'].split('/')[1];

    useEffect(() => {
        if (!id && docs && docs.length) {
            navigate(`${branch}/${docs[0]._id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docs])

    useEffect(() => {
        onSetIsOpen(false);
    }, [id])

    useEffect(() => {
        if (branch) {
            dispatch(getDocs(branch));
        }
    }, [branch, dispatch])

    const handleAddDoc = (isSub) => {
        dispatch(addDocs(branch, {
            type: isSub ? 'sub' : 'menu',
            title: 'New Doc',
            content: '## About'
        }))
    }


    if (!docs) { return <></> }

    return (
        <Rows>
            <Icon i="ellipsis-v" onClick={() => onSetIsOpen(true)} />
            <Header>
                <Between>
                    <h3>Table of content:</h3>
                    <Icon i="times" onClick={() => onSetIsOpen(false)} />
                </Between>
            </Header>
            {
                docs.map((i, index) => (
                    <div key={i._id} className={`row ${i.type}`}>
                        <NavLink to={`${branch}/${i._id}`}>
                            <Line>
                                <div>{`${index + 1}.`}</div>
                                <div>{i.title}</div>
                            </Line>
                        </NavLink>
                    </div>
                ))
            }
            {Settings.ISLOCAL &&
                <Line>
                    <Btn i="plus" onClick={() => handleAddDoc()}>Add Menu</Btn>
                    <Btn i="stream" onClick={() => handleAddDoc(true)}>Add Sub Menu</Btn>
                </Line>
            }
        </Rows>
    )
}

export default Sidebar;