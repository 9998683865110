import React from 'react';
import ReactDOM from 'react-dom';

import App from "./App";
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import './markdown.css';

import { Provider } from "react-redux";
import store from "State/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


