import { Line } from 'UIKit';
import { Icon } from '../Icon/Icon';
import './Btn.css';
export const Btn = (props) => {
    return (
        <button className="Btn" onClick={props.onClick}>
            <Line>
                {props.i && <Icon i={props.i} />}
                {props.children}
            </Line>
        </button>
    )
}