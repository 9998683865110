import axios from 'axios';
import Settings from 'Settings.js';

export const api = axios.create({
    baseURL: Settings.API_ENDPOINT,
    headers: {
        'Content-Type': 'application/json'
    }
})

export const GET = (type, path) => Fetch('get', type, path, null);
export const POST = (type, path, payload) => Fetch('post', type, path, payload);
export const PUT = (type, path, payload) => Fetch('put', type, path, payload);
export const PATCH = (type, path, payload) => Fetch('patch', type, path, payload);
export const DELETE = (type, path, payload) => Fetch('delete', type, path, payload);

const Fetch = (service, type, path, payload) => {
    return async dispatch => {
        try {
            var resp = await api[service](path, payload);
            dispatch({ type, payload: resp.data })
        } catch (error) {
            dispatch({ type, payload: {}, error: error.message })
            console.log(error);
        }
    }
}