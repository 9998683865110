import './Line.css';
export const Line = (props) => {
    return (
        <div className={`Line ${props.className}`}>
            {props.children}
        </div>
    )
}

export const Between = (props) => {
    return <Line {...props} className="Between" />
}

export const Rows = (props) => {
    return <Line {...props} className="Rows" />
}