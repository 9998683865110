import './Grid.css';

export const Grid = (props) => {
    return (
        <div className={`Grid ${props.className || ''}`}>
            {props.children}
        </div>
    )
}

export const GridHeader = (props) => {
    return (
        <Grid {...props} className="header" />
    )
}

export const GridSide = (props) => {
    return (
        <Grid {...props} className="side" />
    )
}