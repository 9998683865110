import { createStore, combineReducers, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";

import { branchesReducer, contentReducer, docsMiddleware, docsReducer } from "./docs";

const reducers = combineReducers({
    docs: docsReducer,
    branches: branchesReducer,
    content: contentReducer
})

export default createStore(reducers, applyMiddleware(ReduxThunk, docsMiddleware));
