import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getBranches } from "State/docs";
import { Icon } from "UIKit";
import { Responsive } from "UIKit";
import { Line, Between } from "UIKit";

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [branches] = useSelector(state => [state.branches]);
    const params = useParams();
    const branch = params['*'].split('/')[0];

    useEffect(() => {
        if (!branch && branches.length) {
            navigate(branches[0].branch);
        }
    }, [branch, branches, navigate])

    useEffect(() => {
        dispatch(getBranches())
    }, [dispatch])

    if (!branches.length) {
        return <></>
    }

    return (
        <Between>
            <Line>
                <Responsive
                    web={(
                        <>
                            <Line>
                                <Icon i="university" />
                                <div>
                                    <h3>ZadaheaD</h3>
                                    <h6>All School</h6>
                                </div>
                            </Line>
                        </>
                    )}
                    mobile={
                        <Icon i="university" />
                    }
                />
            </Line>
            <Line>
                {branches.map(i => (
                    <NavLink key={i._id} to={`/${i.branch.toLowerCase()}`}>{i.branch}</NavLink>
                ))}
            </Line>
            <Responsive
                web={<h6>{new Date().toDateString()}</h6>}
            />
        </Between>
    )
}

export default Header;