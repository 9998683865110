import { GET, PATCH, PUT } from "./fetch";

//actions
export const getBranches = () => (GET('GET_BRANCHES', '/branches'));
export const getDocs = branch => (GET('GET_DOCS', '/' + branch));
export const addDocs = (branch, body) => (PUT('ADD_DOCS', '/' + branch, body));
export const clearDocs = () => ({ type: 'CLEAR_DOCS' });
export const getContent = (branch, id) => (GET('GET_CONTENT', `/${branch}/${id}`));
export const clearContent = () => ({ type: 'CLEAR_CONTENT' });
export const updateCotent = (branch, id, body) => (PATCH('PATCH_CONTENT', `/${branch}/${id}`, body));

//reducers
export const docsReducer = (state = null, action) => {
    switch (action.type) {
        case 'GET_DOCS': return action.payload;
        case 'ADD_DOCS': return action.payload;
        case 'CLEAR_DOCS': return null;
        default: return state;
    }
}

export const branchesReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_BRANCHES': return action.payload;
        default: return state;
    }
}

export const contentReducer = (state = null, action) => {
    switch (action.type) {
        case 'GET_CONTENT': return action.payload;
        case 'CLEAR_CONTENT': return null;
        default: return state;
    }
}

//middleware
export const docsMiddleware = ({ dispatch }) => (next) => (action) => {
    //console.log(action.type);

    if (action.type === 'GET_DOCS') {
        dispatch({ type: 'CLEAR_CONTENT' });
        dispatch({ type: 'CLEAR_DOCS' });
        //console.log('called');
    }

    next(action);
}