
const host = `${window.location.protocol}//${window.location.hostname}`;
const API_URL = `${host}:${process.env.REACT_APP_API_PORT}`;
const STORAGE_URL = `${host}:${process.env.REACT_APP_STORAGE_PORT}`;

const Settings = {
    "direction": "rtl",
    "API_ENDPOINT": `${API_URL}/docs`,
    "API_URL": API_URL,
    "STORAGE_URL": STORAGE_URL,
    "ISLOCAL": process.env.NODE_ENV === 'development'
}

console.log(Settings);

export default Settings;