import './Responsive.css';
export const Responsive = ({ mobile, web }) => {
    return (
        <div className="Responsive">
            {mobile &&
                <div className="mobile">
                    {mobile}
                </div>
            }
            {web &&
                <div className="web">
                    {web}
                </div>
            }
        </div>
    )
}