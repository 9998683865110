import { useState } from "react";
import { useParams } from "react-router-dom";
import { Inner, GridHeader, GridSide } from "UIKit";
import Content from "./Content";

import Header from './Header';
import Sidebar from "./SideBar";

const Home = () => {
    const [isSideOpen, setIsSideOpen] = useState(true);
    const params = useParams();
    const branch = params['*'].split('/')[0];

    return (
        <div branch={branch}>
            <GridHeader>
                <div>
                    <Inner>
                        <Header />
                    </Inner>
                </div>
                <Inner>
                    <GridSide>
                        <div>
                            <Content />
                        </div>
                        <div className={`${isSideOpen ? 'open' : 'closed'}`}>
                            <Sidebar onSetIsOpen={setIsSideOpen} />
                        </div>
                    </GridSide>
                </Inner>
            </GridHeader>
        </div>
    )
}

export default Home;