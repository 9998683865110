import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getContent, updateCotent, clearContent } from "State/docs";

import MDEditor from '@uiw/react-md-editor';
import { Between, Btn, Line, Rows, Input, Center, Loader } from "UIKit";
import Settings from "Settings";

const Content = () => {
    const [value, setValue] = useState('');
    const [title, setTitle] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    const dispatch = useDispatch();
    const [content] = useSelector(state => [state.content]);
    const params = useParams();
    const branch = params['*'].split('/')[0];
    const id = params['*'].split('/')[1];

    useEffect(() => {
        loadContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const loadContent = useCallback(() => {
        if (branch && id) {
            dispatch(clearContent());
            dispatch(getContent(branch, id))
        }
    }, [branch, id, dispatch])

    useEffect(() => {
        loadContent();
    }, [loadContent])

    useEffect(() => {
        setValue(content?.content || '');
        setTitle(content?.title || '');
    }, [content])

    const handleSave = () => {
        dispatch(updateCotent(branch, id, { ...content, content: value, title }));
        setIsEdit(false);
    }

    if (!content) {
        return (
            <Center>
                <Loader />
            </Center>
        )
    }

    return (
        <Rows>
            <div>
                {
                    isEdit ?
                        <Between>
                            <Input value={title} placeholder="add title" onChange={(e) => setTitle(e.target.value)} />
                            <Line>
                                <Btn i="window-close" onClick={() => setIsEdit(false)}>Cancel</Btn>
                                <Btn i="save" onClick={handleSave}>Save</Btn>
                            </Line>
                        </Between>
                        :
                        <Between>
                            <h4>{title}</h4>
                            {Settings.ISLOCAL && <Btn i="edit" onClick={() => setIsEdit(true)}>Edit</Btn>}
                        </Between>
                }
            </div>
            <div style={{ flex: 1 }}>
                {isEdit ?
                    <MDEditor
                        value={value}
                        onChange={setValue}
                    />
                    :
                    <MDEditor.Markdown source={value} />
                }
            </div>
        </Rows>
    )
}

export default Content;